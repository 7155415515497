import { ResponsiveContext, ThemeContext } from 'grommet'
import { useContext, useState, useEffect } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { useLocation } from '@reach/router'

export const useResponsive = () => {
  const size = useContext(ResponsiveContext)
  const themeSizes = useContext(ThemeContext);

  return {
    themeSizes: themeSizes.global.edgeSize,
    size,
    isDesktop: size === 'medium' || size === 'large',
    isMobile: size === 'xsmall' || size === 'small',
  }
}

// Hook
export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}

export const useEditableRegions = page => {
  const { allWpPage } = useStaticQuery(graphql`
    query MyQuery {
      allWpPage {
        nodes {
          contentBlocks {
            editableRegions {
              content
              name
            }
            infoPoints {
              content
              name
              title
            }
          }
          title
        }
      }
    }
  `)

  let obj = {
    editableRegions: {},
    infoPoints: {},
  }

  allWpPage.nodes.map(function(val) {
    if (val.title === page) {
      val?.contentBlocks?.editableRegions?.map(
        regions => (obj.editableRegions[regions.name] = regions.content)
      )
      return val?.contentBlocks?.infoPoints?.map(
        regions => (obj.infoPoints[regions.name] = regions)
      )
    }
  })
  return obj
}

export const useCheckoutPhase = () => {
  const { pathname } = useLocation()
  const [step, setStep] = useState(0)
  const [stepName, setStepName] = useState(0)
  const [isCheckout, setIsCheckout] = useState(0)

  useEffect(() => {
    if (pathname === '/accessorypicker') {
      setIsCheckout(true)
      setStep(1)
      setStepName('accessories')
    } else if (pathname === '/planpicker') {
      setIsCheckout(false)
      setStep(2)
      setStepName('plan')
    } else if (pathname === '/register') {
      setIsCheckout(true)
      setStep(3)
      setStepName('register')
    } else {
      setIsCheckout(false)
      setStep(0)
      setStepName('plan')
    }
  }, [pathname])

  return { step, stepName, pathname, isCheckout }
}
