import { navigate } from 'gatsby'
import { Box, Button, Heading, Layer, Text } from 'grommet'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearError } from '../../state/ui'

const Alert = () => {
  const error = useSelector(state => state.ui.error)
  const dispatch = useDispatch()

  //   useEffect(() => {
  //     dispatch(error)
  //   }, [error])

  const onClose = () => {
    dispatch(clearError())
  }

  return error ? (
    <Layer
      id="hello world"
      position="center"
      onClickOutside={onClose}
      onEsc={onClose}
    >
      <Box pad="medium" gap="small" width="medium">
        <Heading level={3} margin="none">
          {error.title}
        </Heading>
        <Text>{error.message}</Text>

        {error.type === 'login' && (
          <Text>
            <a
              href="#"
              onClick={() => {
                dispatch(clearError())
                navigate('/signin?redirect=register')
              }}
            >
              Log in to your account
            </a>
          </Text>
        )}

        <Box
          as="footer"
          gap="small"
          direction="row"
          align="center"
          justify="end"
          pad={{ top: 'medium', bottom: 'small' }}
        >
          {error.type === 'confirm' ? (
            <>
              <Button
                label={
                  <Text color="white">
                    <strong>Delete</strong>
                  </Text>
                }
                onClick={onClose}
                primary
                color="status-critical"
              />
            </>
          ) : (
            <Button primary label="OK" onClick={onClose} color="brand" />
          )}
        </Box>
      </Box>
    </Layer>
  ) : null
}

export default Alert
