import AmplifyCache from '@aws-amplify/cache';
import Auth from '@aws-amplify/auth'
const isBrowser = typeof window !== `undefined`

export const setUser = user =>
  (window.localStorage.gatsbyUser = JSON.stringify(user))

export const setQuote = quote =>
  (window.localStorage.quotation = JSON.stringify(quote))

export const getQuote = () => {
  if (window.localStorage.quotation) {
    let quote = JSON.parse(window.localStorage.quotation)
    return quote ? quote : {}
  }
  return {}
}

export const clearQuote = () => setQuote({});


const getUser = () => {
  if (window.localStorage.gatsbyUser) {
    let user = JSON.parse(window.localStorage.gatsbyUser)
    return user ? user : {}
  }
  return {}
}

export const isLoggedIn = () => {
  if (!isBrowser) return false

  const user = getUser()
  if (user) return !!user.username
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = async(callback = () => {}) => {
  if (!isBrowser) return
  AmplifyCache.clear();
  await Auth.signOut();
  setUser({})
  callback()
}
