import API from '@aws-amplify/api'
import { useLocation } from '@reach/router'
import {
  Box,
  Button,
  CheckBox,
  Form,
  Heading,
  Layer,
  Paragraph,
  Text,
} from 'grommet'
import { Close } from 'grommet-icons'
import parser, { domToReact } from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { gaEvent, fbEvent } from '../../../helpers'
import { employeesAPI } from '../../../config'
import { useEditableRegions, useResponsive } from '../../../hooks'
import { FormTextInput } from '../../Forms'
import ClipLoader from 'react-spinners/ClipLoader'

const defaultValue = {
  company: '',
  contactEmail: '',
  contactFirstName: '',
  contactLastLame: '',
  email: '',
  firstname: '',
  lastname: '',
  marketing: false,
  phone: '',
  postcode: '',
  // subscribe: false,
}

export const TestRideForm = ({ status, onValidated }) => {
  const location = useLocation()
  const { name, path } = employeesAPI

  const { editableRegions } = useEditableRegions('Test ride sign up')
  const [checked, setChecked] = useState(false)
  const { isMobile } = useResponsive()
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(defaultValue)
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    location?.hash === '#testride' && setOpen(true)
  }, [location])

  const onSubmit = async () => {
    setSubmitting(true)

    const { email, firstname, lastname, phone } = value || {}

    if (!checked) {
      setError('Please agree to our terms and conditions in order to continue.')
      setSubmitting(false)
      return
    }

    API.post(name, `${path}/invite`, {
      body: {
        employeeProperties: {
          testRide: true,
          firstname,
          lastname,
          email,
          phone,
        },
      },
    })
      .then(response => {
        if (response.success) {
          gaEvent('test-ride', {})
          setSubmitted(true)
          gaEvent('test-ride', {})
          setValue(defaultValue)
          setTimeout(() => {
            setSubmitted(false)
            setOpen(false)
          }, 5000)
        }
      })
      .catch(err => setError('There was a problem submitting the form.'))
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        margin={{
          left: isMobile ? null : '50px',
          top: isMobile ? 'medium' : null,
        }}
        size={isMobile ? 'large' : 'medium'}
        primary
        label="Test Ride"
      />
      {open && (
        <Layer
          background={'transparent'}
          position="right"
          full="vertical"
          modal
        >
          <Box
            as="form"
            fill="vertical"
            overflow="auto"
            width="600px"
            alignSelf="center"
            pad={{ horizontal: 'medium', vertical: 'xlarge' }}
            justify="start"
            background="rgba(255,255,255,1)"
          >
            <Button
              icon={<Close />}
              onClick={() => setOpen(false)}
              style={{ position: 'absolute', right: 20, top: 20 }}
            />

            <Box
              flex={false}
              direction="column"
              justify="between"
              align="center"
              margin={{ top: 'medium' }}
            >
              {parser(editableRegions['Title'], {
                replace: ({ name, children }) => {
                  if (name === 'p') {
                    return (
                      <Heading level={2} margin="none" textAlign="center">
                        {submitted ? 'Thank You' : domToReact(children)}
                      </Heading>
                    )
                  }
                },
              })}

              {parser(editableRegions['Body'], {
                replace: ({ name, children }) => {
                  if (name === 'p') {
                    return (
                      <Paragraph textAlign="center">
                        {submitted
                          ? `We'll be in touch shortly.`
                          : domToReact(children)}
                      </Paragraph>
                    )
                  }
                },
              })}
            </Box>

            {!submitted && (
              <Form
                className={`form ${submitted ? 'submitted' : ''}`}
                value={value}
                onChange={(nextValue, { touched }) => {
                  setValue(nextValue)
                }}
                onSubmit={onSubmit}
                onReset={() => setValue(defaultValue)}
              >
                <Heading margin={{ top: 'large', bottom: 'small' }} level={3}>
                  Your Details
                </Heading>

                <Box direction="row" gap="small" alignSelf="center">
                  <FormTextInput
                    name="firstname"
                    placeholder="First name"
                    validate={null}
                  />

                  <FormTextInput
                    name="lastname"
                    placeholder="Last name"
                    validate={null}
                  />
                </Box>

                <Box direction="row" gap="small" alignSelf="center">
                  <FormTextInput
                    name="email"
                    placeholder="Email"
                    validate={null}
                  />

                  <FormTextInput
                    name="phone"
                    placeholder="Phone"
                    validate={null}
                  />
                </Box>

                <Box
                  direction="row"
                  margin={{ vertical: 'medium' }}
                  alignSelf="start"
                  align="start"
                >
                  <CheckBox
                    name="marketing"
                    checked={checked}
                    label={
                      <Paragraph>
                        <Text>I agree to Blike's </Text>
                        <a target="_blank" href="/terms-and-conditions">
                          Terms & Conditions
                        </a>
                      </Paragraph>
                    }
                    onChange={event => setChecked(event.target.checked)}
                  />
                </Box>
                <Box flex={false} as="footer" alignSelf="center">
                  {submitting ? (
                    <ClipLoader
                      color={'black'}
                      loading={submitting ? true : false}
                      size={20}
                    />
                  ) : (
                    <Button
                      loading={submitting}
                      style={{ width: 250 }}
                      alignSelf="start"
                      size="large"
                      label="Submit"
                      type="submit"
                      primary
                    />
                  )}
                  <Text margin={{ top: 'small' }} color="red">
                    {error && error}
                  </Text>
                </Box>
              </Form>
            )}
          </Box>
        </Layer>
      )}
    </>
  )
}

export default TestRideForm
