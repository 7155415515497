import {
  Box,
  ResponsiveContext,
  Grid,
  Heading,
  Paragraph,
  Form,
  FormField,
  TextInput,
  RadioButtonGroup,
  CheckBox,
  Text,
  Button,
} from 'grommet'
import React, { useEffect, useContext, useRef, useState } from 'react'
import Logo from '../../assets/vectors/logo.svg'
import parser, { domToReact } from 'html-react-parser'

import Facebook from '../../assets/vectors/social-facebook.svg'
import Instagram from '../../assets/vectors/social-instagram.svg'
import Twitter from '../../assets/vectors/social-twitter.svg'
import Youtube from '../../assets/vectors/social-youtube.svg'
import { useEditableRegions, useResponsive } from '../../hooks'
import { LinkedIn } from '../../components/Components/Social'
import ClipLoader from 'react-spinners/ClipLoader'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { Checkmark } from 'grommet-icons'
import styled from 'styled-components'
import { useStaticQuery, Link, graphql } from 'gatsby'

const defaultValue = {
  FNAME: '',
  LNAME: '',
  TYPE: '',
  EMAIL: '',
  // subscribe: false,
}

const StyledFormField = styled(FormField)`
  color: white;
  font-size: 32px;
  font-weight: 700 !important;
  border: none;
  border-radius: 0;
  border: none !important;
  outline: none !important;
  border-bottom: 1px solid #6fff6f !important;

  > div {
    border: none !important;
    outline: none !important;
  }
  &:focus {
    box-shadow: none;
  }
  // style={{
  //   paddingLeft: 0,
  //   borderRadius: 0,
  //   border: 'none',
  //   borderBottom: '1px solid #6fff6f',
  //   width: '100%',
  // }} &::placeholder {
  //       font-size: 32px;
  //       font-weight: 700;
  //       opacity: 0.5;
  //     }
`

const StyledTextInput = styled(TextInput)`
  color: white;
  font-size: 26px;
  font-weight: 700 !important;
  border: none !important;
  outline: none !important;
  padding-left: 0;
  font-family: 'Circular Std Bold';

  &:focus {
    box-shadow: none;
  }
  // style={{
  //   paddingLeft: 0,
  //   borderRadius: 0,
  //   border: 'none',
  //   borderBottom: '1px solid #6fff6f',
  //   width: '100%',
  // }} &::placeholder {
  //       font-size: 32px;
  //       font-weight: 700;
  //       opacity: 0.5;
  //     }
`

const SignUpForm = ({ status, message, onValidated }) => {
  const { isDesktop } = useResponsive()
  const inputRef = useRef()
  const [value, setValue] = useState(defaultValue)
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const submit = event => {
    onValidated({
      EMAIL: event.value.EMAIL,
    })
  }

  useEffect(() => {
    // status ===
    setLoading(status === 'sending')
    setError(status === 'error' ? message : null)

    if (status === 'success') {
      setSubmitted(status === 'success')
      setValue(defaultValue)
      setTimeout(() => {
        setSubmitted(false)
      }, 5000)
    }
  }, [message, status])

  return (
    <>
      <Box className="form-container">
        <Box className={`message ${submitted ? 'submitted' : ''}`}>
          <Paragraph>Thank you. We'll be in touch.</Paragraph>
        </Box>
        <Form
          className={`form ${submitted ? 'submitted' : ''}`}
          value={value}
          onChange={(nextValue, { touched }) => {
            console.log(nextValue)
            setValue(nextValue)
          }}
          onReset={() => setValue(defaultValue)}
          onSubmit={submit}
        >
          <Box
            gap={isDesktop ? 'small' : 'none'}
            flex
            fill="horizontal"
            direction="row"
            wrap
            align="start"
            justify="start"
          >
            <Box flex={true} width={{ min: '300px' }} justify="center">
              <StyledFormField
                id="email"
                htmlFor="type"
                name="EMAIL"
                required
                validate={[
                  {
                    regexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  },
                  email => {
                    if (email && email.length === 1)
                      return 'must be a valid email'
                    return undefined
                  },
                ]}
              >
                <StyledTextInput
                  className="footer-email-input"
                  plain={false}
                  id="type"
                  name="EMAIL"
                  placeholder="Email"
                />
              </StyledFormField>
            </Box>
            <Button type="submit" plain className="loader">
              <Box
                margin="none"
                round="full"
                background="brand"
                justify="center"
                align="center"
                height="40px"
                width="40px"
              >
                {loading ? (
                  <ClipLoader
                    color={'black'}
                    loading={loading ? true : false}
                    size={20}
                  />
                ) : (
                  <Checkmark
                    color="dark-1"
                    style={{
                      backgroundColor: '#00E37C',
                      top: 2,
                      position: 'relative',
                    }}
                  />
                )}
              </Box>
            </Button>
          </Box>

          <div
            style={{ fontSize: 14, color: 'red' }}
            dangerouslySetInnerHTML={{ __html: error }}
          ></div>
        </Form>
      </Box>
    </>
  )
}

const TrustBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null)
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])
  return (
    <div
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      className="trustpilot-widget" // Renamed this to className.
      // [ long list of data attributes...]
      data-locale="en-GB"
      data-template-id="5419b6a8b0d04a076446a9ad"
      data-businessunit-id="60ae2fcdd74e340001c8f0e0"
      data-style-height="24px"
      data-style-width="100%"
      data-theme="dark"
      data-min-review-count="10"
    ></div>
  )
}

const Footer = () => {
  const { isDesktop, isMobile, size } = useResponsive()
  const url = `https://greenswitchgroup.us6.list-manage.com/subscribe/post?u=df79cd65a6c3a0023b278e722&amp;id=9f04efe444`
  const { editableRegions } = useEditableRegions('Footer')

  const data = useStaticQuery(
    graphql`
      {
        wpMenu(locations: { eq: GATSBY_FOOTER_MENU }) {
          id
          menuItems {
            nodes {
              parentId
              label
              path
              childItems {
                nodes {
                  path
                  label
                }
              }
            }
          }
        }
      }
    `
  )

  const {
    wpMenu: { menuItems },
  } = data

  const InternalLink = ({ child }) => {
    return (
      <Link className="header-link" to={child.path}>
        <Paragraph
          margin={{ top: 'none', bottom: '5px' }}
          size="medium"
          color="white"
        >
          {child.label}
        </Paragraph>
      </Link>
    )
  }

  const ExternalLink = ({ child }) => {
    return (
      <a className="header-link" href={child.path}>
        <Paragraph
          margin={{ top: 'none', bottom: '5px' }}
          size="medium"
          color="white"
        >
          {child.label}
        </Paragraph>
      </a>
    )
  }

  const renderMenuItems = () => {
    let parentMenus = menuItems.nodes.filter(e => e.parentId === null)

    return (
      parentMenus &&
      parentMenus.map((parentItem, index) => {
        return (
          <Box key={index} flex="grow">
            <Heading
              size="large"
              level={4}
              color="white"
              responsive={false}
              margin={{ top: 'none', horizontal: 'none', bottom: 'medium' }}
            >
              {parentItem.label}
            </Heading>
            <Box flex="grow">
              {parentItem.childItems.nodes.map((child, index) => {
                return child?.path?.includes('http') ? (
                  <ExternalLink child={child} key={index} />
                ) : (
                  <InternalLink child={child} key={index} />
                )
              })}
            </Box>
          </Box>
        )
      })
    )
  }

  return (
    <Box
      background="dark-1"
      direction="column"
      justify="end"
      flex={'grow'}
      style={{ zIndex: 5000 }}
    >
      <Box
        fill={'horizontal'}
        pad={{ horizontal: 'large', top: 'large', bottom: 'small' }}
        flex="grow"
        alignSelf="center"
        style={{ overflow: 'hidden' }}
        width={{ max: '1360px' }}
      >
        <Box
          flex="grow"
          style={{ position: 'relative' }}
          fill={'horizontal'}
          align="start"
          direction={isMobile ? 'column' : 'row'}
          gap={isDesktop ? 'large' : 'medium'}
        >
          <Box flex="grow" direction="column">
            <Box justify="start" align="start" flex="shrink">
              <Logo width="80px" height="75px" />
            </Box>
            <Box
              flex="grow"
              margin={{ top: 'medium', bottom: 'none' }}
              // fill={isDesktop ? false : 'horizontal'}
            >
              <Heading level={4} size="large" margin="none" color="white">
                Find out more
              </Heading>
              <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                  <SignUpForm
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                  />
                )}
              />
            </Box>

            <Box flex="shrink" margin={{ vertical: 'large' }} margin="none">
              <Heading level={4} size="large" margin="none" color="white">
                0800 099 6142
              </Heading>
              <Heading level={4} size="large" margin="none">
                <a
                  href="mailto:hello@blike.co.uk"
                  target="_blank"
                  style={{ color: 'white', border: 'none' }}
                >
                  hello@blike.co.uk
                </a>
              </Heading>
              <TrustBox />
            </Box>
          </Box>
          <Box flex={false} gap="large" align="end" justify="start">
            <Box direction="row" gap="medium">
              <a
                href="https://www.linkedin.com/company/blike/?viewAsMember=true"
                style={{ border: 'none' }}
                target="_blank"
              >
                <LinkedIn height="50px" width="50px" />
              </a>
              <a
                href="https://www.facebook.com/BlikeHappy"
                style={{ border: 'none' }}
                target="_blank"
              >
                <Facebook height="50px" width="50px" />
              </a>
              <a
                href="https://www.instagram.com/blikehappy"
                style={{ border: 'none' }}
                target="_blank"
              >
                <Instagram height="50px" width="50px" />
              </a>
              <a
                href="https://twitter.com/BlikeHappy"
                style={{ border: 'none' }}
                target="_blank"
              >
                <Twitter height="50px" width="50px" />
              </a>
            </Box>
            <Box flex="grow" alignSelf={isMobile ? 'start' : 'end'}>
              <Box
                flex
                align="start"
                direction={isDesktop ? 'row' : 'column'}
                gap={'large'}
              >
                {renderMenuItems()}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box flex="shrink" fill="horizontal" justify="end">
          <Text
            color="white"
            margin={{ top: 'small', bottom: 'none' }}
            style={{ lineHeight: 1, fontSize: '12px' }}
          >
            {parser(editableRegions['Green Switch Info'])}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
