import { Box, ResponsiveContext, Grid, Heading, Paragraph } from 'grommet'
import React, { useContext } from 'react'
import { LinkedinOption } from 'grommet-icons'

export const LinkedIn = ({ height = "40px", width = "40px" }) => {
  return (
    <Box
      round="full"
      height={height}
      width={width}
      background="#00E37C"
      justify="center"
      align="center"
    >
      <LinkedinOption color="white" />
    </Box>
  )
}
