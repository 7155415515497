import { FormField, TextInput } from 'grommet'
import React from 'react'
import styled from 'styled-components'

const StyledFormField = styled(FormField)`
  > div {
    border-radius: 10px;
    border-width: 2px;
  }
`

export const FormTextInput = ({
  name = '',
  info = '',
  icon = null,
  placeholder = '',
  validate = [
    { regexp: /^[a-z]/i },
    value => {
      if (value && value.length === 1) return 'must be >1 character'
      return undefined
    },
  ],
  required = true,
  ...rest
}) => {
  
  return (
    <StyledFormField
      fill={true}
      required={required}
      htmlFor={name}
      name={name}
      info={info}
      // validate={required && validate}
    >
      <TextInput
        plain={false}
        id={name}
        name={name}
        icon={icon}
        placeholder={placeholder}
        {...rest}
      />
    </StyledFormField>
  )
}
