import { graphql, Link, useStaticQuery } from 'gatsby'
import { Box, Paragraph, Heading, Button } from 'grommet'
import React, { useEffect } from 'react'
import Logo from '../../../assets/vectors/logo.svg'
import { useResponsive } from '../../../hooks'
import { MobileMenu } from './MobileMenu'
import TestRideForm from './TestRideForm'
import { AppUser } from '../../Auth'
import { useDispatch, useSelector } from 'react-redux'
import { switchPurchase } from '../../../state/ui'
import * as queryString from 'query-string'
import { useLocation } from '@reach/router'
import Recycle from '../../../assets/vectors/recycle.svg'

const SubscriptionSwitch = () => {
  const { purchaseType } = useSelector(state => state.ui)
  const dispatch = useDispatch(switchPurchase)

  return (
    <Button
      onClick={() => dispatch(switchPurchase())}
      label={purchaseType}
      size="small"
      primary={purchaseType === 'cycletowork'}
      active={purchaseType === 'subscription'}
      secondary={purchaseType === 'subscription'}
    ></Button>
  )
}

const Header = ({ noMenu }) => {
  const { isMobile, isDesktop } = useResponsive()
  const location = useLocation()
  const { cycletowork } = queryString.parse(location.search)

  const data = useStaticQuery(
    graphql`
      {
        wpMenu(locations: { eq: GATSBY_HEADER_MENU }) {
          id
          menuItems {
            nodes {
              parentId
              label
              path
              childItems {
                nodes {
                  path
                  label
                }
              }
            }
          }
        }
      }
    `
  )

  const {
    wpMenu: { menuItems },
  } = data

  const isLoggedIn = AppUser.isLoggedIn()
  const { employerName } = useSelector(state => state.employee)

  let newNode = {
    parentId: null,
    label: 'Recycle',
    path: '/recycle',
    childItems: {
      nodes: [],
    },
  }

  useEffect(() => {
    if (cycletowork === 'true') return
    // Find the index of the 'Bikes' node
    const bikesIndex = menuItems?.nodes?.findIndex(
      node => node.label === 'Bikes'
    )

    // Insert the new node after the 'Bikes' node, only if it's not already there
    if (
      bikesIndex !== -1 &&
      !menuItems.nodes.some(node => node.label === newNode.label)
    ) {
      menuItems.nodes.splice(bikesIndex + 1, 0, newNode)
    }
  }, [menuItems])

  const renderMenuItems = (mobile = false) => {
    const InternalLink = ({ item }) => {
      return (
        <Link className="header-link" to={item.path}>
          <Paragraph
            margin={{ right: isMobile ? 'none' : 'large' }}
            size={mobile ? 'large' : null}
          >
            {item.label}
          </Paragraph>
        </Link>
      )
    }

    const ExternalLink = ({ item }) => {
      return (
        <a className="header-link" href={item.path}>
          <Paragraph
            margin={{ right: isMobile ? 'none' : 'large' }}
            size={mobile ? 'large' : null}
            color="black"
          >
            {item.label}
          </Paragraph>
        </a>
      )
    }

    const RecycleLink = ({ item }) => {
      return (
        <a className="header-link" href={item.path}>
          <Paragraph
            style={{ display: 'inline' }}
            size={mobile ? 'large' : null}
            color="black"
          >
            Re
          </Paragraph>
          <Recycle
            width={15}
            height={15}
            fill="#74b73d"
            stroke="#74b73d"
            style={{ position: 'relative', top: 1, left: 0 }}
          />
          <Paragraph
            style={{ display: 'inline' }}
            margin={{ right: isMobile ? 'none' : 'large' }}
            size={mobile ? 'large' : null}
            color="black"
          >
            ycle
          </Paragraph>
        </a>
      )
    }

    return (
      <>
        {mobile && (
          <Link className="header-link" to={'/'}>
            <Paragraph
              size={mobile ? 'large' : null}
              margin={{ right: isMobile ? 'none' : 'large' }}
            >
              {'Home'}
            </Paragraph>
          </Link>
        )}
        {menuItems &&
          menuItems.nodes.map((item, index) => {
            return item?.path?.includes('http') ? (
              <ExternalLink item={item} key={index} />
            ) : item?.path?.includes('recycle') ? (
              <RecycleLink item={item} key={index} />
            ) : (
              <InternalLink item={item} key={index} />
            )
          })}
        <a href={!isLoggedIn ? '/signin' : '/profile'} className="header-link">
          <Paragraph size={mobile ? 'large' : null}>
            {isLoggedIn ? 'My Account' : 'Sign In'}
          </Paragraph>
        </a>
        <TestRideForm />
      </>
    )
  }

  const styles = {
    desktop: {
      pad: { horizontal: 'large', top: 'medium', bottom: 'none' },
      width: { max: '1360px' },
      background: 'background',
      direction: 'row',
      justify: 'between',
    },
    mobile: {
      pad: { horizontal: 'large', top: 'large', bottom: 'none' },
      width: { max: '1360px' },
      background: 'background',
      direction: 'row',
      justify: 'between',
    },
  }

  const renderMenu = () => {
    return !isMobile ? (
      <Box flex fill direction="row" align="center" justify="end">
        {renderMenuItems(false, null)}
      </Box>
    ) : (
      <MobileMenu renderMenuItems={() => renderMenuItems(true)} />
    )
  }

  return (
    <>
      <Box
        fill="horizontal"
        pad={{ vertical: 'xsmall' }}
        background="brand"
        items="center"
        justify="center"
      >
     

        <Box>
          <Paragraph
            size="large"
            color="white"
            margin={{ horizontal: 'auto', vertical: 'none' }}
          >
            50% off joining fees on all{' '}
            <Paragraph
              style={{ display: 'inline' }}
              size={'large'}
              color="white"
            >
              Re
            </Paragraph>
            <Recycle
              width={15}
              height={15}
              fill="white"
              stroke="white"
              style={{ position: 'relative', top: 1, left: 0 }}
            />
            <Paragraph
              style={{ display: 'inline' }}
              margin={{ right: isMobile ? 'none' : '5px' }}
              size={'large'}
              color="white"
            >
              ycle
            </Paragraph>
            bikes
          </Paragraph>
        </Box>

        <Link
          to={'/recycle'}
          margin={{ horizontal: 'auto', vertical: 'none' }}
          style={{ display: 'inline-block', textAlign: 'center' }}
        >
          <Paragraph
            style={{ textDecoration: 'underline' }}
            margin={{ horizontal: 'auto', vertical: 'none' }}
            size={'medium'}
            color="white"
          >
            shop now
          </Paragraph>
        </Link>
      </Box>
      <Box
        fill="horizontal"
        flex={false}
        alignSelf="center"
        {...(isMobile ? styles.mobile : styles.desktop)}
      >
        <Box direction="row" align="start" gap="20px">
          <a href="/" className={'logo'}>
            <Logo
              width={isMobile ? '75px' : '100px'}
              height={isMobile ? '50px' : '75px'}
            />
          </a>
          {employerName && (
            <Heading
              margin={{ top: '5px', bottom: 0 }}
              size="small"
              color="brand"
            >{`& ${employerName}`}</Heading>
          )}
        </Box>

        {!noMenu && renderMenu()}
      </Box>
    </>
  )
}

export default Header
