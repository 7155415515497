import { Box, Layer, Button } from 'grommet'
import React, { useState } from 'react'
import { Close } from 'grommet-icons'
import { Menu } from 'grommet-icons'

export const MobileMenu = ({ renderMenuItems }) => {
  const [open, setOpen] = useState(false)

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        icon={<Menu />}
        onClick={() => setOpen(true)}
        style={{ position: 'absolute', right: 20, top: 20 }}
      />
      {open && (
        <Layer background={'transparent'} full animation="fadeIn">
          <Box
            flex
            fill
            direction="column"
            align="center"
            justify="center"
            background="white"
          >
            <Button
              onClick={onClose}
              icon={<Close />}
              style={{ position: 'absolute', right: 20, top: 20 }}
            />
            {renderMenuItems()}
          </Box>
        </Layer>
      )}
    </>
  )
}
