import { Box, Grommet } from 'grommet'
import 'modern-normalize/modern-normalize.css'
import React from 'react'
import MainSwoosh from '../../assets/images/swooshmain.svg'
import { useWindowSize } from '../../hooks'
import '../../scss/styles.scss'
import Alert from '../Components/Alert'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import { theme } from './theme'
import Seo from '../../components/Seo'

export function Layout({ children, isUserNav, title, swoosh, noMenu = false }) {
  const size = useWindowSize()
  
  return (
    <Grommet
      theme={theme}
      full="min"
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <Seo title={title} />
      <Box style={{ overflowX: 'hidden', position: 'relative' }} flex="grow">
        <Header noMenu={noMenu}/>
        {swoosh && (
          <Box
            width="150%"
            height="50%"
            style={{
              position: 'absolute',
              top: '40%',
              left: 0,
              backgroundImage: `url(${MainSwoosh})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '150% 80%',
              backgroundPosition: 'center top',
            }}
          />
        )}
        <Box
          flex="grow"
          fill="horizontal"
          alignSelf="center"
          width={{ max: '1360px' }}
          pad={{ horizontal: 'none', bottom: 'large' }}
          style={{ zIndex: 1 }}
        >
          {children}
        </Box>

        <Footer />
      </Box>
      <Alert />
    </Grommet>
  )
}

export function AppContent({ children }) {
  return null;
}
